import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SecondaryLayout from "../components/layouts/SecondaryLayout"
import ServicePage from "../containers/ServicePage"

import useMessages from "../hooks/useMessages"
const SecondPage = () => {
  const [messages, format] = useMessages(["services"])

  const data = useStaticQuery(graphql`
    query {
      imageSection1: file(
        relativePath: { eq: "page-blocus-assiste-section-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageSection2: file(
        relativePath: { eq: "page-blocus-assiste-section-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <SecondaryLayout>
      <ServicePage
        service="semaine-de-soutien-scolaire"
        messages={messages}
        format={format}
        images={[
          data.imageSection1.childImageSharp.fluid,
          data.imageSection2.childImageSharp.fluid,
        ]}
      />
    </SecondaryLayout>
  )
}

export default SecondPage
